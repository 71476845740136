import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll'

export default class Header extends React.Component {
  constructor() {
    super()
    this.state = { isOpen: false }
  }

  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  goBack = () => {
    window.history.back()
  }

  render() {
    const { airportName, backText, location } = this.props
    let pathname = ""
    if (location) { pathname = location.pathname }

    return (
      <header>
        <div className="nav-header hidden-below-lap">
          <div className="nav-brand">
            <Link to="/">
              The Centurion<sup>&reg;</sup> Network
            </Link>
            {airportName &&
              <span> - {airportName}</span>
            }
          </div>
        </div>
        <div className={this.state.isOpen ? 'nav open': 'nav'}>
          <button className="nav-toggle" onClick={this.toggleOpen}>The Centurion<sup>&reg;</sup> Lounge</button>
          <ul>
            {pathname !== "/" &&
              <li className="hidden-lap-and-up"><Link to="/">Home</Link></li>
            }
            <li><Link to="/info/access/">Access</Link></li>
            {airportName || pathname === "/" ? (
              <li><button onClick={() => scrollTo('#locations-anchor')}>Locations</button></li>
            ) : (
              <li><Link to="/#locations">Locations</Link></li>
            )}
            <li><Link to="/digitalwaitlist/">Digital Waitlist</Link></li>
            <li><Link to="/info/faq/">FAQs</Link></li>
            <li><Link to="/info/terms/">Terms &amp; Conditions</Link></li>
          </ul>
        </div>
        {backText &&
          <div className="nav-secondary hidden-lap-and-up">
            <div className="container container--wide">
              <button onClick={this.goBack} className="back-item">
                <i className="icon-ios-arrow-left left"></i>
                {backText}
              </button>
            </div>
          </div>
        }
      </header>
    )
  }
}

Header.propTypes = {
  airportName: PropTypes.string,
  backText: PropTypes.string,
}
